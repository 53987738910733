<template>
   <div>
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                        hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="items" :search="search" class="elevation-1"></v-data-table>
                
    </div>
</template>

<script>

    export default {
        
        name: 'MfSimpleTable',

        props: {
            headers: {
                type: Array,
                default: () => [],

            },
            items: {
                type: Array,
                default: () => [],

            },
        },

        data: () => ({
            search: '',
        }),
    }
</script>