<template>
  <div style="background-color: lightgrey;">
    <v-toolbar dense rounded outlined class="mb-5">
      <v-spacer></v-spacer>
      <div>
        <v-btn color="primary" plain @click="addUserIsVisible = !addUserIsVisible">
          <v-icon>mdi-account-plus</v-icon>
          <span v-t="'add'"></span>
        </v-btn>
      </div>
    </v-toolbar>
    <v-expand-transition>
      <v-card class="mb-2 px-3 mx-3 py-1" v-show="addUserIsVisible">
        <v-row>
          <v-col cols="12" sm="11">
            <v-row>
              <v-col>
                <v-text-field v-model="newUser.username" :label="$t('username')" :disabled="savingUser"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="newUser.email" :label="$t('email', {r:''})" :disabled="savingUser"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="newUser.firstName" :label="$t('firstName')" :disabled="savingUser"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="newUser.lastName" :label="$t('lastName')" :disabled="savingUser"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="1" class="pt-6">
            <v-btn medium color="primary" @click="saveNewUserWithTrait" :loading="savingUser" :disabled="savingUser">
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>
    <div class="pa-3">
      <v-data-table
          :headers="headers"
          :items="iamUsers"
          :items-per-page="20"
          :expanded.sync="expanded"
          item-key="uuid"
          show-expand
          :single-expand="true"
          class="elevation-1">
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" @click="setCustomerType(item)">{{ $t('setAs') }}
            <v-icon style="margin-left:5px">mdi-hammer-wrench</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" sm="2" @click="sendTechlaWelcomeEmail(item)">
                <v-btn plain v-t="'techlaWelcomeEmail'"></v-btn>
              </v-col>
              <v-col cols="12" sm="2" @click="printCredentials(item)">
                <v-btn plain v-t="'printCredentialsLabel'">Print Credentials Label</v-btn>
              </v-col>
              <v-col cols="12" sm="2" @click="sendB2BEmail(item)" v-if="showB2BCredentialsButton">
                <v-btn plain v-t="'b2bEmail'"></v-btn>
              </v-col>
              <!-- <v-col cols="12" sm="2">
                <v-btn plain disabled>Forgot password email</v-btn>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn plain disabled>Reset password</v-btn>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn plain disabled>Disable</v-btn>
              </v-col> -->
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {get, call, sync} from "vuex-pathify";

export default {
  name: "IamUsersTable",
  data() {
    return {
      iamUsers: [],
      iamTraitCollection: [],
      newUser: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        realmId: 1,
        emailVerified: true,
        userEnabled: true
      },
      savingUser: false,
      iamTrait: {},
      addUserIsVisible: false,
      expanded: []
    }
  },
  methods: {
    saveNewUserWithTrait() {
      const traitCustomValueKey = {
        'Agent': 'agentCode',
        'Customer': 'customerCode',
        'USA Customer': 'customerCode',
        'BR Customer': 'customerCode'
      };
      this.savingUser = true;
      this.apiIamUserPost(this.newUser).then((resUser) => {
        const payload = {};
        payload.uuid = resUser.uuid;
        payload.traitId = this.iamTrait.id;
        payload.customAttributeValues = {};
        payload.customAttributeValues[traitCustomValueKey[this.iamTraitName]] = this.iamTraitCustomValue;
        this.apiIamUserUuidTraitsTraitIdPost(payload).then(() => {
          this.loadUsers();
          this.newUser.username = "";
          this.newUser.email = "";
          this.newUser.firstName = "";
          this.newUser.lastName = "";
          this.savingUser = false;
          this.addUserIsVisible = false;
        }).catch(() => {
          this.savingUser = false;
        })
      }).catch(() => {
        this.savingUser = false;
      });
    },
    loadUsers() {
      const payload = {};
      payload.iamTrait = this.iamTrait;
      payload.customValue = this.iamTraitCustomValue;
      this.apiIamTraitIdUsersCustomValueGet(payload)
      .then((res) => {
        this.iamUsers = res.map(el => ({ ...el, actions : false }));
      });
    },
    sendTechlaWelcomeEmail(iamUser) {
      this.apiIamUserUuidSendTechlaWelcomeEmailPost(iamUser.uuid);
    },
    printCredentials(iamUser) {
      this.apiIamUserUuidPrintCredentialsPost(iamUser.uuid);
    },
    sendB2BEmail(iamUser) {
      this.apiIamUserUuidSendB2BEmailPost(iamUser.uuid);
    },
    setCustomerType(iamUser) {
      let uuid = iamUser.uuid;
      let traitId = this.iamTraitCollection.find(el => el.name == 'CustomerFerr').id;
      let customAttributeValues = { 'CustomerType' : 'Machieraldo' }
      this.apiIamUserUuidTraitsTraitIdPost({ uuid, traitId, customAttributeValues })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      })
    },
    initialize() {
      if(!!this.customerId) {
        let user = this.customers.find(x => x.id == this.customerId);
        this.newUser.username = user?.code ?? "";
        this.newUser.email = user?.email ?? "";
        this.newUser.firstName = user?.businessName ?? "";
        this.newUser.lastName = user?.email?.split('@')[0] ?? "";
      }
      this.apiGetTraits()
      .then((res) => {
        this.iamTraitCollection.length = 0;
        this.iamTraitCollection.push(...res);
        this.iamTrait = res.find(x => x.name === this.iamTraitName);
        this.loadUsers();
      })
    },
    ...call("iam/*")
  },
  computed: {
    showB2BCredentialsButton: get("tenantConfiguration/showB2BCredentialsButton"),
    customers: get("customers/allCustomers"),
    headers(){
      return [
        {value: 'username', text: this.$t('username')},
        {value: 'email', text: this.$t('email', {r:''})},
        {value: 'firstName', text: this.$t('firstName')},
        {value: 'lastName', text: this.$t('lastName')},
        {value: 'action', text: this.$t('actions')}
      ]
    }
  },
  mounted() {
    this.initialize();
  },
  props: {
    iamTraitName: {
      type: String,
      default: ""
    },
    iamTraitCustomValue: {
      type: String,
      default: ""
    },
    customerId: {
      type: String,
      default: 0
    }
  }
}
</script>

<style scoped>

</style>
