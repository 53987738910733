<template>
  <material-card class="v-card--material-stats" v-bind="$attrs" v-on="$listeners"  :heading="year" color="primary">

        <template #subtitle>
          <v-row>
             <v-col cols="12" md="0" lg="2" >
              <!-- <div class="text-center text-h6 v-card__subtitle--material-stats" v-text="title_1" />
              <div class="text-center text-h3 v-card__subtitle--material-stats" v-text="year" /> -->
          </v-col>
            <v-col cols="12" md="6" lg="5">
                  <div class="text-center text-h6 v-card__subtitle--material-stats" v-text="title_1" />
                  <div class="text-center text-h3 v-card__subtitle--material-stats" v-text="value_1" />
          </v-col>
            <v-col cols="12" md="6" lg="5"> 
                  <div class="text-center text-h6 v-card__subtitle--material-stats" v-text="title_2" />
                  <div class="text-center text-h3 v-card__subtitle--material-stats" v-text="value_2" />
            </v-col>
          </v-row>       
        </template>
    
    
    <template #actions>
      <slot name="actions" />
    </template>


  </material-card>

</template>

<script>
  export default {
    name: 'CustomMaterialStatCard',

    props: {
      title_1: String,
      title_2: String,
      value_1: String,
      value_2: String,
      year: String,
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
.v-card--material 
.v-card--material__title 
  color: #999999 
  font-size: .875rem !important 
  margin-left: auto 
  text-align: right 
.v-card__subtitle--material-stats 
  color: #3C4858
</style>