<template>
    <td v-if="!dataItem.inEdit" :class="className">{{ dataItem[field]}}</td>
    <td v-else>
        <v-autocomplete
            autofocus
            auto-select-first
            class="mini-autocomplete"
            v-model="model" 
            :items="getItems()"
            @change="change"
        ></v-autocomplete>
    </td>
</template>
<script>
import { get, call } from "vuex-pathify";
export default {
    name : "DropdownProductCode",
    created() {
        this.initialize()
    },
    computed: {
        items : get("items/items"),
        kits : get("itemKits/itemKits"),
    },
    props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String,
        itemFamilyId: Number
    },
    data: () => ({
        model: null
    }),
    methods: {
        initialize: function() {
            this.initItemKitCollection();
            this.initItemCollection();
        },
        getItems : function () {
            this.model = this.dataItem.itemCode;

            const itemsForFamily = this.items.filter(item => item.itemFamilyId === this.itemFamilyId);
            const kitsForFamily = this.kits.filter(item => item.familyId === this.itemFamilyId);
            return [...itemsForFamily.map(el => ({ text : `${el.code} - ${el.barcode} - ${el.description}`, value : el.code})), ...kitsForFamily.map(el => ({ text : `${el.code} - ${el.description}`, value : el.code }))];
        },
        change(e) {
            let selection = Object.assign({isKit: false}, this.items.find(el => el.code === e) || {});
            if (!selection.code) {
              selection = Object.assign({isKit: true}, this.kits.find(el => el.code === e) || {});
            }
            this.$emit('change', selection, selection);
        },
        focus() {
            this.$nextTick(() => this.$refs['autocomplete'].$el.focus());
        },
        ...call("items/*"),
        ...call("itemKits/*"),
    }
}
</script>