<template>
    <td v-if="!dataItem.inEdit" :class="className">{{ getPercentage(dataItem[field]) }}</td>
    <td v-else>
        <v-autocomplete
            auto-select-first
            class="mini-autocomplete"
            v-model="model" 
            :items="getItems()"
            @change="change"
        ></v-autocomplete>
    </td>
</template>
<script>
import { get, call } from "vuex-pathify";
export default {
    name : "DropdownVATRate",
    created() {
        this.initialize();
    },
    computed: {
        vatPercentages: get("vats/vatPercentages")
    },
    props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    data: () => ({
        model: null
    }),
    methods: {
        initialize: function() {
            this.initVatCollection();
        },
        getItems : function () {
            this.model = this.dataItem.vatPercentageId;
            return this.vatPercentages.map(el => ({ text : `${el.code}-${el.description}`, value : el.id}));
        },
        getPercentage: function(id) {
            return this.vatPercentages.find(x => x.id == id)?.percentage;
        },
        change(e) {
            this.$emit('change', e);
        },
        ...call("vats/*"),
    }
}
</script>